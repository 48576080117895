import { Link, useNavigate } from "react-router-dom";
import { LOGOUT } from "@/auth/actions";
import Logo from "@/components/Logo";
import { useDispatch } from "react-redux";
import { Navbar, Icon } from "react-bulma-components";
import { useMe } from "@/api/auth";

export function TopBar(): React.ReactElement {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data: user } = useMe();

  const logout = () => {
    dispatch({ type: LOGOUT });
  };

  return (
    <Navbar color="dark">
      <Navbar.Menu>
        <Navbar.Container>
          <Navbar.Brand>
            <Navbar.Item key="logo" renderAs={Link} to="/">
              <Logo />
            </Navbar.Item>
          </Navbar.Brand>
        </Navbar.Container>
        <Navbar.Container align="right">
          <Navbar.Item key="targetSelector"></Navbar.Item>
          <Navbar.Item
            key="docs"
            data-tooltip="Documentation"
            className="has-tooltip-bottom"
            onClick={() => {
              window.open("http://app.enertel.ai/docs/intro", "_blank");
            }}
          >
            <Icon>
              <i aria-hidden="true" className="fa fa-book" />
            </Icon>
          </Navbar.Item>
          {user?.subscription_type === "bidding" && (
            <Navbar.Item
              key="bidding"
              data-tooltip="Bidding"
              className="has-tooltip-bottom"
              onClick={() => navigate("/app/bidding")}
            >
              <Icon>
                <i aria-hidden="true" className="fa fa-chart-line" />
              </Icon>
            </Navbar.Item>
          )}
          <Navbar.Item
            key="user"
            data-tooltip={
              user && `User: ${user.email} \nGroup: ${user.group_name}`
            }
            className="has-tooltip-bottom"
          >
            <Icon onClick={() => navigate("/app/user")}>
              <i aria-hidden="true" className="fa fa-user" />{" "}
            </Icon>
          </Navbar.Item>
          <Navbar.Item
            key="logout"
            data-tooltip={"Logout"}
            className="has-tooltip-bottom"
            onClick={logout}
          >
            <Icon size="medium">
              <i className="fa-solid fa-right-from-bracket" />{" "}
            </Icon>
          </Navbar.Item>
        </Navbar.Container>
      </Navbar.Menu>
    </Navbar>
  );
}

export default TopBar;
