import React from "react";
import * as Sentry from "@sentry/react";
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import AppLayout from "./layouts/AppLayout";
import EmptyLayout from "./layouts/EmptyLayout";
import Login from "./sections/Login";
import Dashboard from "./sections/Dashboard";
import UserProfile from "./sections/UserProfile";
import Bidding from "./sections/Bidding";

import "bulma/css/bulma.min.css";
import "bulma-tooltip/dist/css/bulma-tooltip.min.css";
import "./base.css";
import "maplibre-gl/dist/maplibre-gl.css";

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

function App(): React.ReactElement {
  return (
    <BrowserRouter>
      <SentryRoutes>
        <Route path="app" element={<AppLayout />}>
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="user" element={<UserProfile />} />
          <Route path="bidding" element={<Bidding />} />
          <Route
            path="grid"
            element={<Navigate to="/app/dashboard" replace />}
          />
          <Route
            path="models"
            element={<Navigate to="/app/dashboard" replace />}
          />
        </Route>
        <Route path="/" element={<EmptyLayout />}>
          <Route path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />
          <Route path="*" element={null} />
        </Route>
      </SentryRoutes>
    </BrowserRouter>
  );
}

export default Sentry.withProfiler(App);
