import React, { useEffect, useState } from "react";
import axios from "axios";
import { GridGame } from "../../types";

function Bidding(): React.ReactElement {
  const [games, setGames] = useState<GridGame[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchGames = async () => {
      try {
        const response = await axios.get("/api/grid/games");
        setGames(response.data);
        setLoading(false);
      } catch (err) {
        console.error("Error fetching grid games:", err);
        setError("Failed to load grid games. Please try again later.");
        setLoading(false);
      }
    };

    fetchGames();
  }, []);

  return (
    <div className="section">
      <h1 className="title">Grid Games</h1>

      {loading && <p>Loading grid games...</p>}

      {error && <div className="error-message">{error}</div>}

      {!loading && !error && games.length === 0 && (
        <p>No grid games available.</p>
      )}

      {!loading && !error && games.length > 0 && (
        <div className="games-container">
          <table className="games-table">
            <thead>
              <tr>
                <th>ID</th>
                <th>Type</th>
                <th>Source</th>
                <th>Created At</th>
                <th>Updated At</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {games.map((game) => (
                <tr key={game.id}>
                  <td>{game.id}</td>
                  <td>{game.type}</td>
                  <td>{game.source}</td>
                  <td>{new Date(game.created_at).toLocaleString()}</td>
                  <td>{new Date(game.updated_at).toLocaleString()}</td>
                  <td>{game.is_deleted ? "Deleted" : "Active"}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default Bidding;
